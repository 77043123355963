import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import { getsensorelectra, getsensorelectra_dag, getsensorelectra_maand, getsensorelectra_uur, getsensorelectra_last } from "../Reducers/actions";
import { fixmessage } from "../../../Reducers/loading/actions";
import moment from "moment";
import LineGraph from "./Components/linegrapselectra";
import { useParams } from "react-router-dom";
import BarGraph from "./Components/graphelectra";
import { Link } from "react-router-dom";
import Tabel from "./Components/tabelelectra";
import FilterSensor from "./Components/filterelect";
import KiesDate from "../Components/datumkeuze";
import Schakelen from "./Components/schakelen";
import Voorspellen from "../Components/voorspellen";
const SensorItemGas = (props) => {
  const { id } = useParams();
  const [load, Setload] = useState(false);
  const [datum, Setdatum] = useState({ startdate: moment().subtract(7, "days").toDate(), enddate: new Date() });
  const [vorigperiode, Setvorigperiode] = useState({ startdate: moment().subtract(337, "hours").toDate(), enddate: moment().subtract(170, "hours").toDate() });
  const [datum_hour, Setdatum_hour] = useState({
    startdate: moment().subtract(1, "days").toDate(),
    enddate: new Date(),
    temp_id: "eui-a84041df118356ba",
  });
  const [datum_day] = useState({ startdate: moment().subtract(6, "days").toDate(), enddate: new Date() });
  const [datum_month] = useState({ startdate: moment().subtract(12, "months").toDate(), enddate: new Date() });
  const [gemiddelde, Setgemiddelde] = useState(0);
  const [stand, setStand] = useState(0);
  const [standbegin, setStandbegin] = useState(0);
  const [standperiode, setStandperiode] = useState(0);
  const [standbeginperiode, setStandbeginperiode] = useState(0);
  const [standdag, setStandDag] = useState(0);
  const [laststand, setLastStand] = useState(0);

  const [max, Setmax] = useState(0);

  useEffect(() => {
    getvals();
    getvals_dag();
    getvals_uur();
    getvals_maand();
    getvals_last();
  }, [datum, datum_hour]);

  function changedata(data) {
    if (data.enddate && data.startdate) {
      setTimeout(() => {
        var duration = moment.duration(moment(data.enddate).diff(moment(data.startdate)));
        var hours = duration.asHours();
        Setvorigperiode({
          startdate: moment(data.startdate)
            .subtract(hours + 3, "hours")
            .toDate(),
          enddate: moment(data.startdate).subtract(-1, "hours").toDate(),
        });
        Setdatum(data);
      }, 100);
    }
  }

  function getvals() {
    props.getsensorelectra(id, { ...datum, vorigperiode }).then((data) => {
      if (data.results) {
        const transformed = data.results.data
          .map((value, key) => {
            if (key > 0) {
              return {
                value: data.results.data[key - 1].value - value.value,
                orgvalue: value.value,
                datum: value.datum,
              };
            } else {
              return {
                value: 0,
                orgvalue: value.value,
                datum: value.datum,
              };
            }
          })
          .filter((item) => item !== null);

        Setload(true);

        if (transformed.length > 0) {
          Setgemiddelde(transformed.reduce((acc, cur) => acc + cur.value, 0) / transformed.length);
          Setmax(Math.max(...transformed.map((o) => o.value)));
          setStandbegin(transformed[transformed.length - 1].orgvalue);
          setStand(transformed[0].orgvalue.toFixed(2));
          setStandperiode(data.results.vorigeperoide && data.results.vorigeperoide.length > 0 ? data.results.vorigeperoide[data.results.vorigeperoide.length - 1].value : 0);

          setStandbeginperiode(data.results.vorigeperoide && data.results.vorigeperoide.length > 0 ? data.results.vorigeperoide[0].value : 0);
        }
      } else {
        props.fixmessage("warning", "Er is een fout opgtreden bij het laden van de sensoren");
      }
    });
  }

  function getvals_dag() {
    props.getsensorelectra_dag(id, datum_day).then((data) => {
      if (!data.results) {
        props.fixmessage("warning", "Er is een fout opgtreden bij het laden van de sensoren");
      }
    });
  }
  function getvals_last() {
    props.getsensorelectra_last(id).then((data) => {
      if (!data.results) {
        props.fixmessage("warning", "Er is een fout opgtreden bij het laden van de sensoren");
      }

      if (data.results.data && data.results.data[0] && data.results.data[0].decoded_payload.totalkwh) setLastStand(data.results.data[0].decoded_payload.totalkwh);
      if (data.results.sensor === "UC300" && data.results.soort === "electra" && data.results.data[0] && data.results.data[0].value) setLastStand(data.results.data[0].value);
      if (data.results.data && data.results.data[0] && data.results.data[0].decoded_payload["Active Energy Import T1"]) setLastStand(data.results.data[0].decoded_payload["Active Energy Import T1"].value / 1000);
    });
  }
  function getvals_maand() {
    props.getsensorelectra_maand(id, datum_month).then((data) => {
      if (!data.results) {
        props.fixmessage("warning", "Er is een fout opgtreden bij het laden van de sensoren");
      }
    });
  }
  function getvals_uur() {
    props.getsensorelectra_uur(id, datum_hour).then((data) => {
      if (!data.results) {
        props.fixmessage("warning", "Er is een fout opgtreden bij het laden van de sensoren");
      } else {
        if (data.results.data && data.results.data[0]) setStandDag((data.results.data[0].value - data.results.data[data.results.data.length - 1].value).toFixed(2));
      }
    });
  }
  function getkwh(data) {

    var gas = 0;
    if (data && data.length > 0) {
      if (data[0].decoded_payload.current) {
        gas = (data[0].decoded_payload.current *100).toFixed(0);
      } else if (data.length > 1) {
        gas = data[0].value - data[1].value;
      }
    }
    return Number(gas);
  }

  const { sensor } = props;
  function battery(value) {
    if (value && value && value.decoded_payload && value.decoded_payload.Volt) {
      if (value.decoded_payload.Volt >= 3.6) {
        return <i className="fas fa-battery-full green"></i>;
      } else {
        return <i className="fas fa-battery-quarter red"></i>;
      }
    }
  }

  function luodate(data) {
    if (data && data.data && data.data[0] && data.data[0].datum) {
      return moment(data.data[0].datum).fromNow();
    } else {
      return null;
    }
  }
  function changedag(data) {
    Setdatum_hour({
      startdate: moment(data.datum.format()).subtract(1, "days").toDate(),
      enddate: data.datum.format(),
      temp_id: "eui-a84041df118356ba",
    });
  }

  return (
    <div className="box box-default show-elecra">
      <div className="box-header with-border">
        <h3 className="box-title"> {sensor.item.naam}</h3>
        <div className="box-tools pull-right">
          <Schakelen device={sensor.item} />
          <Voorspellen sensor={sensor.item} alert={sensor} sensor_id={sensor.item._id} />
          <Link className="btn btn-warning btn-sm float-right mx-2" role="group" to={"/sensoren/sensor/edit/" + sensor.item._id}>
            Wijzigen
          </Link>
        </div>
      </div>
      <div className="box box-default">
        <div className="box-header with-border">
          <FilterSensor device={props.sensor.item} changedata={changedata} laststand={laststand} gemiddelde={gemiddelde} max={max} stand={stand} standbegin={standbegin} standperiode={standperiode} standbeginperiode={standbeginperiode} />
        </div>
      </div>
      <div className="col-md-12 no-float">
        <div className=" box-primary">
          <div className="box-body" />
          {load && (
            <div className="row p-0">
              <div className="col-12 col-md-4 col-lg-2 p-0">
                <div className="p-4">
                  <h4>Actueel verbruik</h4>
                  <span className="valuekwh"> {getkwh(sensor.last.data)} W</span>

                  <div className="details">Laatste meting {luodate(sensor.last)}</div>
                </div>
              </div>
              <div className="col-12 col-md-8 col-lg-10 p-0">
                <LineGraph sensor={sensor.item} kleur="#D96A29" />
              </div>
              <div className="col-12">
                <div className="p2">Batterij: {battery(sensor.item.data[0])} </div>
              </div>
              <div className="col-12 col-md-6 col-lg-6 p-0">
                <h3 className="p-4">Metingen afgelopen 7 dag</h3>
                <BarGraph sensor={sensor.dag} kleur="#B1BF41" type="dag" />
              </div>
              <div className="col-12 col-md-6 col-lg-6 p-0">
                <h3 className="p-4">Metingen per maand</h3>
                <BarGraph sensor={sensor.maand} kleur="#D96A29" type="maand" />
              </div>
              <div className="col-12 col-md-12 col-lg-12">
                <div className="row">
                  <div className="col-12 col-md-8 col-lg-8">
                    <div className="box-titel">
                      <h3 className="">Metingen 24 uur ({standdag} kWh)</h3>
                    </div>
                  </div>
                  <div className="col-8 col-md-4 col-lg-4 offset-4 offset-md-0">
                    <KiesDate changedata={changedag} />
                  </div>
                </div>

                <BarGraph sensor={sensor.uur} kleur="#D96A29" type="uur" />
              </div>
              <div className="col-12">
                <h3 className="p-4">Gegevens tabel metingen</h3>
                <Tabel sensor={sensor.item} />
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

function mapStateToProps(state) {
  return { sensor: state.sensoren };
}

export default connect(mapStateToProps, { getsensorelectra, fixmessage, getsensorelectra_dag, getsensorelectra_maand, getsensorelectra_uur, getsensorelectra_last })(SensorItemGas);
